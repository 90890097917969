import React from "react";
import styled from "styled-components";
import { rem, media } from "../../utils/style";
import IngredientsMapSidebarItem from "./IngredientsMapSidebarItem";

const Sidebar = styled.div.attrs({
  className: "col-sm-3 flush-left mt-5 mt-md-7 mb-sm-5 mb-md-7",
})`
  flex: 1;
  position: relative;
  margin-right: 8.33333%;

  @media (min-width: 750px) {
    margin-left: calc((100vw - 700px) / 2);
  }

  @media (min-width: 960px) {
    margin-left: calc((100vw - 934px) / 2);
  }

  @media (min-width: 1200px) {
    margin-left: calc((100vw - 1170px) / 2);
  }

  ${media.mobile`
     flex: none;
  `};
`;

const Source = styled.h1`
  font-size: ${rem(16)};
  text-transform: uppercase;
  margin-bottom: ${rem(40)};

  ${media.mobile`
    margin-bottom: ${rem(24)};
  `};
`;

export default class IngredientsMapSidebar extends React.Component {
  render() {
    const { activeItemIndex, onItemClick, locationGroup } = this.props;
    let ingredients = this.props.ingredients;

    return (
      <Sidebar>
        <Source>{locationGroup}</Source>
        {ingredients.map((ingredient, i) => {
          const active = activeItemIndex === i;

          return (
            <IngredientsMapSidebarItem
              key={`sidebar-item-${i}`}
              active={active}
              onClick={e => onItemClick(e, i)}
              ingredient={ingredient}
            />
          );
        })}
      </Sidebar>
    );
  }
}

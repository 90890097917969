import React from "react";
import styled from "styled-components";
import { rem, Color, media, Font, responsive } from "../../utils/style";
import { Icons } from "../../utils/svg";
import MagicLink from "../MagicLink";
import Img from "gatsby-image";
import Text from "../Text";

const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-bottom: 0;

    div[class*="Header"] {
      border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    }

    &.active {
      padding-bottom: 0;
    }
  }

  &.active {
    padding-bottom: 40px;

    div[class*="Header"] {
      border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    }
  }
`;

const Header = styled.div.attrs({})`
  width: 100%;
  padding: 16px 0;
  border-top: 2px solid ${Color.ritualBlue};
  display: flex;
  align-items: center;
  cursor: pointer;

  ${responsive.md`
    padding: 24px  0;
  `}
`;

const Body = styled.div.attrs({})`
  ${Font.circular} font-weight: 500;
  display: none;

  > a {
    font-size: ${rem(16)};
    text-decoration: underline;
  }

  ${Wrapper}.active & {
    display: block;
  }
`;

const BodyDescription = styled.p`
  ${Font.dutch}
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  margin: 24px 0 16px;

  ${responsive.md`
    margin: 24px 0;
  `}
`;

const IngredientImage = styled(Img)`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #f1f5f7;

  ${media.notDesktop`
    width: 56px;
    height: 56px;
  `};
`;

const Heading = styled.span.attrs({})`
  ${Font.circular}
  font-size: ${rem(22)};
  font-weight: 500;
  flex: 2;
  margin: 0 12px;

  ${media.notDesktop`
    font-size: ${rem(18)};
  `};
`;

const Indicator = styled.div`
  width: 32px;
  height: 24px;
  position: relative;

  ${Wrapper}.active & {
    transform: rotate(-180deg);
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    transform: translateX(-50%) translateY(-50%);
  }
`;

export default class IngredientsMapSidebar extends React.Component {
  render() {
    const { ingredient, onClick, active } = this.props;
    const { name, description, image, slug } = ingredient.node;

    const activeClass = active ? "active" : null;
    const linkTarget = `/ingredients/${slug}`;

    return (
      <Wrapper
        className={activeClass}
        id={`header-` + slug}
        aria-expanded={active}
        aria-controls={`collapse-` + slug}
      >
        <Header onClick={onClick}>
          <IngredientImage
            fluid={image.thumbnail}
            fadeIn={false}
            alt={image.description}
            style={{
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
              borderRadius: "50%",
              color: "#F1F5F7",
              backgroundColor: "#F1F5F7",
            }}
          />
          <Heading>{name}</Heading>
          <Indicator>
            <Icons.CaretDown />
          </Indicator>
        </Header>
        <Body id={`collapse-` + slug} aria-labelledby={`header-` + slug}>
          <BodyDescription>{description}</BodyDescription>
          <MagicLink
            to={linkTarget}
            className="-underline"
            ariaLabel={"Learn more about " + name}
          >
            <Text id="general.learn-more" defaultmessage="Learn More" />
          </MagicLink>
        </Body>
      </Wrapper>
    );
  }
}
